import clsx from 'clsx';
import React, { useState } from 'react';
import OutlinedHeartIcon from '@heroicons/react/24/outline/HeartIcon';
import SolidHeartIcon from '@heroicons/react/24/solid/HeartIcon';
import {
    CommentFragment,
    GetCommentsQuery,
    useCommentDeleteMutation, useCommentLikeDeleteMutation,
    useCommentLikeMutation,
} from '../../../graphql';
import { CommentForm } from './CommentForm';

interface IProps {
    globalId: string
    user?: GetCommentsQuery['me']
    comment: CommentFragment;
}

export const Comment: React.FC<IProps> = ({ globalId, user, comment }) => {
    const [showReplyForm, setShowReplyForm] = useState(false);

    const [deleteComment] = useCommentDeleteMutation();
    const [likeComment] = useCommentLikeMutation();
    const [unlikeComment] = useCommentLikeDeleteMutation();

    const handleLikeComment = async () => {
        if (user) {
            if (comment.isLiked) {
                await unlikeComment({
                    variables: { id: comment.id },
                    refetchQueries: ['GetComments'],
                    awaitRefetchQueries: true
                });
            } else {
                await likeComment({
                    variables: { id: comment.id },
                    refetchQueries: ['GetComments'],
                    awaitRefetchQueries: true
                });
            }
        }
    };

    const handleDeleteComment = async () => {
        await deleteComment({
            variables: { id: comment.id },
            refetchQueries: ['GetComments'],
            awaitRefetchQueries: true
        });
    };

    const onShowReplyForm = () => {
        setShowReplyForm(true);
    };

    const onCloseReplyForm = () => {
        setShowReplyForm(false);
    };

    return (
        <div className="item" style={{ marginLeft: `${comment.level}rem` }}>
            <div className="item__info">
                {comment.publishedBy?.avatar &&
                    <div className="avatar">
                        <img src={comment.publishedBy.avatar.thumbnailUrl} alt={comment.publishedBy.avatar.alt} />
                    </div>
                }
                <div className="wrapper">
                    <div className="name">{comment.publishedBy.name}</div>
                    <div className="company">{comment.publishedBy.company}</div>
                </div>
            </div>
            <div className="item__text"><p>{comment.text}</p></div>
            <div className="item__footer">
                <div className="comment__like">
                    {
                        comment.likesCount
                            ? <SolidHeartIcon title="Поддержали" onClick={handleLikeComment} className={clsx({ 'is-active': comment.isLiked } )} />
                            : <OutlinedHeartIcon title="Поддержать" onClick={handleLikeComment} />
                    }
                    {comment.likesCount > 0 && comment.likesCount}
                </div>
                <div className="comment__date">{new Date(comment.publishedAt).toLocaleString()}</div>
                {user && <a className="comment__answer" onClick={onShowReplyForm}>Ответить</a>}
                {user?.id === comment.publishedBy.id &&
                    <>
                        {/*<a className="comment__edit">Изменить</a>*/}
                        <a className="comment__delete" onClick={handleDeleteComment}>Удалить</a>
                    </>
                }
            </div>
            {showReplyForm && user && <CommentForm autoFocus globalId={globalId} user={user} parentId={comment.id} onClose={onCloseReplyForm} />}
        </div>
    );
};
