import { decode, encode } from 'js-base64';

export interface GlobalId {
    id: string;
    __typename: string;
}

export function fromGlobalId(objectId: string): GlobalId {
    const parts = decode(objectId).split(':');
    return {
        __typename: parts[0],
        id: parts[1]
    };
}

export function toGlobalId(type: string, id: string): string {
    return encode(`${type}:${id}`);
}
