import React from 'react';
import BarLoader from 'react-spinners/BarLoader';

interface IProps {
    text?: string;
}

const Spinner: React.FC<IProps> = ({ text }) => {
    return (
        <div className="bar-loader">
            <p>{text ?? 'Загрузка данных...'}</p>
            <BarLoader width="80%" color="#123abc"/>
        </div>
    );
};

export default Spinner;
