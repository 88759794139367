import { ApolloProvider } from '@apollo/client';
import { loadDevMessages, loadErrorMessages } from '@apollo/client/dev';
import React from 'react';
import { render } from 'react-dom';
import { apolloClient } from '../../graphql';
import { toGlobalId } from '../../utils/globalId';
import { CommentsTree } from './components';

import './styles/default.scss';

declare global {
    function createComments(element: Element, params: {type: string, id: string}): void
}

interface IProps {
    globalId: string
}

if (__DEV__) {
    loadDevMessages();
    loadErrorMessages();
}

const App: React.FC<IProps> = ({ globalId }) => (
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <CommentsTree globalId={globalId}/>
        </ApolloProvider>
    </React.StrictMode>
);

export const createComments = (element: Element, params: {type: string, id: string}) => {
    const globalId = toGlobalId(params.type, params.id);

    render(<App globalId={globalId} />, element);
};

globalThis.createComments = createComments;
