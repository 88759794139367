import React from 'react';
import { CommentFragment, GetCommentsQuery } from '../../../graphql';
import { Comment } from './Comment';

interface IProps {
    globalId: string
    user?: GetCommentsQuery['me']
    comments: CommentFragment[];
}

export const CommentTree: React.FC<IProps> = ({ comments, ...props }) => {
    return (
        <div className="cell items">
            {comments.map(comment => (
                <Comment key={comment.id} comment={comment} {...props} />
            ))}
        </div>
    );
};
