import React from 'react';
import { Button } from 'react-foundation';
import { useForm } from 'react-hook-form';
import {
    CommentCreateInput,
    CommentCreateMutation,
    GetCommentsQuery,
    useCommentCreateMutation,
} from '../../../graphql';

interface IProps {
    globalId: string
    parentId?: string
    user: Exclude<GetCommentsQuery['me'], null>
    autoFocus?: boolean
    onClose?: () => void
}

export const CommentForm: React.FC<IProps> = ({ globalId, parentId, user, autoFocus, onClose }) => {

    const handleSuccess = (data: CommentCreateMutation) => {
        if (data?.commentCreate?.errors.length === 0) {
            reset();
            if (onClose) {
                onClose();
            }
        }
    };

    const [createComment, { loading }] = useCommentCreateMutation({
        onCompleted: handleSuccess
    });

    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty, isValid, errors }
    } = useForm<CommentCreateInput>({
        mode: 'all',
        defaultValues: {
            parent: parentId,
            text: '',
        }
    });

    const onSubmit = async (input: CommentCreateInput) => {
        await createComment({
            variables: { id: globalId, input },
            refetchQueries: ['GetComments'],
            awaitRefetchQueries: true
        });
    };

    return (
        <div className="create-comment">
            {user.avatar &&
                <div className="avatar avatar__author">
                    <img src={user.avatar.previewUrl} alt={user.avatar.alt} />
                </div>
            }
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
                <textarea autoFocus={autoFocus} disabled={loading} rows={5} placeholder="Написать комментарий" {...register('text')} />
                {errors.text && <div className="form-error is-visible">{errors.text.message}</div>}
                <div className="action">
                    <Button isDisabled={!isDirty || !isValid || loading} className="san-marino">
                        {loading ? 'Отправка...' : 'Отправить'}
                    </Button>
                    {onClose && <Button className="san-marino" isHollow onClick={onClose}>Закрыть</Button>}
                </div>
            </form>
        </div>
    );
};
