interface Edge<T> {
    node: T;
}

interface Connection<T> {
    edges: Edge<T>[] | undefined | null;
}

export function mapEdgesToItems<T>(
    data?: Connection<T> | undefined | null
): T[] | undefined {
    return data?.edges?.map(({ node }) => node);
}
