import React from 'react';
import { createPortal } from 'react-dom';
import { useGetCommentsQuery } from '../../../graphql';
import Spinner from '../../../shared/Spinner';
import { mapEdgesToItems } from '../../../utils/maps';
import { CommentForm } from './CommentForm';
import { CommentTree } from './CommentTree';
import { LoginBanner } from './LoginBanner';

interface IProps {
    globalId: string
}

export const CommentsTree: React.FC<IProps> = ({ globalId }) => {

    const { data, loading } = useGetCommentsQuery({
        variables: { id: globalId },
    });

    if (!data && loading) {
        return <Spinner text="Загрузка комментариев..." />;
    }

    const comments = mapEdgesToItems(data?.comments || { edges: []}) || [];
    const counterContainer = document.querySelector('#comments-counter');

    return (
        <>
            {counterContainer && createPortal(<>{data?.comments?.totalCount ?? 0}</>, counterContainer)}
            <div className="grid-y grid-margin-y comments__area">
                <div className="cell header">
                    <div className="title">
                        <div className="wrapper">
                            <h3>Комментарии</h3>
                            {data?.comments?.totalCount ? <small>{data.comments.totalCount}</small> : ''}
                        </div>
                    </div>
                </div>
                {comments && <CommentTree globalId={globalId} user={data?.me} comments={comments} />}
                <div className="cell form">
                    {data?.me ? <CommentForm globalId={globalId} user={data.me}/> : <LoginBanner />}
                </div>
            </div>
        </>
    );
};

export default CommentsTree;
